import * as React from "react";
import { Link } from "gatsby";

import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function BlackAndWhite() {
  return (
    <Layout>
      <Helmet title="Black and White | Michael Weslander" />

      <h1>Black and White</h1>

      <h2>Michael Weslander</h2>

      <h4>Key: Em</h4>

      <h4>Tempo: 126</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>
            <Ch>Em</Ch>The mystery of this moment is holding her tight
          </p>

          <p>
            <Ch>Em/C</Ch>Too close for comfort, not enough to fight
          </p>

          <p>
            <Ch>Em/D</Ch>For her these city lights
          </p>

          <p>
            <Ch>Em/C</Ch>Are convoluted<Ch>Em/D</Ch> shades of gray <Ch>Em</Ch>
          </p>
        </Verse>
        <Verse>
          <p>All that she's been waiting for and everything she reads</p>
          <p>Is lost in translation, covered in disease</p>
          <p>But she believes equality is hiding in these shades of gray</p>
        </Verse>
        <Chorus>
          <p>When will we recognize</p>
          <p>Ourselves between the black and white</p>
          <p>In calls for higher walls and fewer facts</p>
          <p>Don't forget beauty breathes</p>
          <p>between the white and black</p>
        </Chorus>
        <Verse>
          <p>She plans to tour the universe, touch every life she meets</p>
          <p>Though she lives in Minnesota, her mind is overseas</p>
          <p>Before she leaves she'll paint</p>
          <p>All these picket fences Shades of gray</p>
        </Verse>
        <Verse>
          <p>She'll walk across the desert, swim the bearing straight</p>
          <p>Though sometimes met with apathy, with jealousy or hate</p>
          <p>Her strength resides in finding</p>
          <p>All the kindness in these shades of gray</p>
        </Verse>
        <Chorus>
          <p>As these continents collide</p>
          <p>And life grows between the black and white</p>
          <p>In calls for higher walls and fewer facts</p>
          <p>Don't forget beauty lives</p>
          <p>between the white and black</p>
        </Chorus>
        Bridge
        <Chorus>
          <p>When will we stop</p>
          <p>and recognize</p>
          <p>The lives we live between the black and white</p>
          <p>Will our edges wane or must they wax</p>
          <p>Can beauty grow between the white and black</p>
          <p>Beauty breaths between the white and black</p>
          <p>Beauty she's between the white and black</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
